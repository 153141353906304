import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const OurTeamPage = () => {
  return (
    <Layout pageTitle="Our Team">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../media/our_team_hero.jpg"
                alt=""
                aspectRatio={2 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>COMPANY</h2>
                <h1>Our Team</h1>
                <h3>
                  Established in 2000, Wessuc is a family owned and operated business that cares about the environment and how our operations affect it. We don't just pump, clean, inspect, and haul. We continue to expand our services and our capabilities to make a greener and environmentally safer place to live, work, and play.
                </h3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <p>
                We do all of this with a safety-first mindset and a quest for the
                latest in technological advances. We’re dedicated to constantly
                improving our processes and finding new ways to meet the needs of
                our customers. The status quo is never enough and at Wessuc it never
                will be.
              </p>
            </Col>
            <Col>
              <p>
                It’s why we’re the leader in our industry and the reason our
                relationships with clients go beyond to just getting the job done.
                We really do care about and are proud of everything we do. It’s in our
                DNA and the only way we know how to do business.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row>
            <Col>
              <h3>LEADERSHIP TEAM</h3>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col xl={3} lg={3}>
              <StaticImage 
                src="../media/shane_vanveen.jpg"
                alt="headshot of Shane VanVeen"></StaticImage>
            </Col>
            <Col xl={9} lg={9}>
              <h4>SHANE VANVEEN</h4>
              <h5>President</h5>
              <p>
                By the time he was 20 years old, Shane knew exactly what he wanted to do. He had already worked part-time in the biosolids industry where he became passionate about the benefits that biosolids provided plant growth and soil improvement. He teamed up with his brother, Hank, in April of 2000 and together, they started up Wessuc. Over the years, he has worked on many interesting programs that focused on better ways to manage land programs and tank clean outs in Ontario. From helping with new equipment designs such as overhead loaders and flow controlled tractors to new methods of creating clean water and healthy soil, Shane continues to pursue optimization through innovation. He is married to his high school sweetheart and has six wonderful children.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col xl={3} lg={3}>
              <StaticImage 
                src="../media/hank_vanveen.jpg"
                alt="headshot of Hank VanVeen"></StaticImage>
            </Col>
            <Col xl={9} lg={9}>
              <h4>HANK VANVEEN</h4>
              <h5>Vice-President</h5>
              <p>
                Hank graduated with an Environmental Engineering degree from the University of Waterloo in 1999. One year later, he started Wessuc with his brother, Shane. Since then, he has focused on helping others manage their waste responsibly. It’s a passion that has seen Wessuc expand into a wide range of services.
              </p>
              <p>
                Hank serves on a the Board of Directors as Treasurer of the Timothy Christian School. Hank married in 2000 and today has five children. In addition to motorcycles, he enjoys soccer, and basketball. He has helped as an assistant coach in the local house league.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col xl={3} lg={3}>
              <StaticImage 
                src="../media/ed_glasbergen.jpg"
                alt="headshot of Ed Glasbergen"></StaticImage>
            </Col>
            <Col xl={9} lg={9}>
              <h4>ED GLASBERGEN</h4>
              <h5>Director of Finance</h5>
              <p>
                Ed joined Wessuc in September of 2019. Ed is responsible for all areas of finance and administration, including financial reporting, budgeting, treasury, risk management and along with the collaboration of the Senior Leadership team, plan for the Company’s next wave of growth.
              </p>
              <p>
                Ed is a Chartered Professional Accountant (Canada) and Certified Public Accountant (USA) and has over 25 years of experience in various senior corporate finance, business development and executive roles.
              </p>
              <p>
                Prior to joining Wessuc, Ed served in Senior Business Development and Finance roles at Energy+, Brant County Power, BlackBerry and Wescam.
              </p>
              <p>
                Ed serves on the Board of Directors as Treasurer of the St. Joseph’s Lifecare Foundation and is Treasurer of his church. He also has a variety of other interests, including travelling, camping, hockey and baseball.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col xl={3} lg={3}>
              <StaticImage 
                src="../media/phil_cron.jpg"
                alt="headshot of Phil Cron"></StaticImage>
            </Col>
            <Col xl={9} lg={9}>
              <h4>PHIL CRON</h4>
              <h5>Director of Business Development</h5>
              <p>
                Formerly with Cambridge Towel for 10 years as their Director of Sales and Marketing, Phil joined Wessuc in December of 2016 as Director Sales. His has brought another dimension to the Wessuc team by focusing on developing sales opportunities and having fun while winning. Married in 1996 and two children later, Phil enjoys time with his family which includes vacations and working out. He also finds time for rugby and golf.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} ms={1} sm={1} xs={1}>
            <Col xl={3} lg={3}>
              <StaticImage 
                src="../media/rob_dalingwater.jpg"
                alt="headshot of Rob Dalingwater"></StaticImage>
            </Col>
            <Col xl={9} lg={9}>
              <h4>ROB DALINGWATER</h4>
              <h5>Director of Human Resources &amp; Safety</h5>
              <p>
                Rob joined Wessuc in March 2020. An enthusiastic and persuasive Human Resources leader, Rob engages with employees, supervisors and managers to enhance personal, team and organizational performance. He is adept at building relationships and works collaboratively to implement practical HR and Safety policies, programs and best practices that exemplify Wessuc’s Values.
              </p>
              <p>
                His diverse background in Human Resources and Safety operations and strategy includes senior HR roles with WIS International and St. Joseph’s Healthcare Hamilton.
              </p>
              <p>
                Rob aspires to a healthy lifestyle that includes running, hiking, backcountry canoeing and camping.
              </p>
            </Col>
          </Row>
        </Container>
      </main>
      <MustRead />
    </Layout>
  )
}

export default OurTeamPage
